<template>
    <FormWrapper
    >
    <PageTitle title="Salary Fixation" slot="title" link="/helpContent/salaryFixation"/>
      <ValidationObserver ref="validator">
        <div style="width: 100%;margin-left: 10%;">
          <table
            class="salaryContainer"
            width="800"
            border="0"
            cellspacing="0"
            cellpadding="3"
          >
            <tbody>
              <tr>
                <td width="130">
                  <strong
                    >Name<span class="Mandatory"> </span>:
                    <span>{{ formRight.name }}</span></strong>
                </td>
                <td colspan="2">
                  <strong
                    >Department : <span>{{ formRight.department }}</span></strong
                  >
                </td>
              </tr>
              <tr>
                <td style=" width:28%;">
                  <strong
                    >Last Increment Date :&nbsp;
               
                    <span>{{ formRight.lastIncrement }}</span></strong
                  >
                </td>
                <td colspan="2">
                  <strong
                    >Present Increment Date <span class="redcls">*</span> :&nbsp;
                    <span v-if="viewMode">{{ formRight.presentIncrement }}</span>
                  <div v-if="!viewMode" style="width: 130px;
      position: absolute;
      margin-top: -21px;
      margin-left: 156px;">
                    <inputDate 
                      v-model="formRight.presentIncrement"
                      
                    />
                  </div>
              
                  </strong>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Details</strong>
                </td>
                <td align="center">
                  <strong>Previous</strong>
                </td>
                <td align="center">
                  <strong>Present</strong>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Designation<span class="redcls">*</span></strong> &nbsp;
                </td>
                <td align="center">
                  <span
                    id="ctl00_ContentPlaceHolder1_lblPastDesign"
                    disabled="disabled"
                    >{{ formLeft.designation }}</span
                  >
                </td>
                <td align="center">
                  <span class="spanClass" v-if="viewMode">{{ formRight.designationName }}</span>
                  <!-- <FormSelect
                    :items="designationList"
                    item-name="NAME"
                    item-value="ID"
                    v-model="formRight.designation"
                    class="Dropstyle"
                    v-if="!viewMode"
                  /> -->
                  <select v-model="formRight.designation" class="Dropstyle" v-if="!viewMode">
      <Option v-for="(designationList, i) in designationList" :value="getItemValue(designationList.ID)" :key="i">{{ getItemName(designationList.NAME) }}</Option>
    </select>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Group<span class="redcls">*</span></strong>
                </td>
  
                <td align="center">
                  <span
                    id="ctl00_ContentPlaceHolder1_lblPastGroup"
                    disabled="disabled"
                    >{{ formLeft.group }}</span
                  >
                </td>
                <td align="center">
                  <span v-if="viewMode">{{ formRight.groupName }}</span>
                  <!-- <FormSelect
                    :items="hrGroupList"
                    item-name="name"
                    item-value="id"
                    v-model="formRight.hrGroupId"
                    class="Dropstyle"
                    v-if="!viewMode"
                  /> -->
                  <select v-model="formRight.hrGroupId" class="Dropstyle" v-if="!viewMode" @change="setDataByGrade($event)">
      <Option v-for="(hrGroupList, i) in hrGroupList" :value="getItemValue(hrGroupList.id)" :key="i">{{ getItemName(hrGroupList.name) }}</Option>
    </select>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Grade<span class="redcls">*</span></strong>
                </td>
  
                <td align="center">
                  <span
                    id="ctl00_ContentPlaceHolder1_lblPastGrade"
                    disabled="disabled"
                    >{{ formLeft.grade }}
                  </span>
                </td>
                <td align="center">
                  <span v-if="viewMode">{{ formRight.gradeName }} </span>
  
                  <!-- <FormSelect
                    :items="gradeList"
                    item-name="Grade"
                    item-value="Grade"
                    v-model="formRight.grade"
                    :onChange="setDataByGrade"
                    class="Dropstyle"
                    v-if="!viewMode"
                  /> -->
                  <select v-model="formRight.grade" class="Dropstyle" @change="setDataByGrade($event)" v-if="!viewMode">
      <Option v-for="(gradeList, i) in gradeList" :value="getItemValue(gradeList.Grade)" :key="i">{{ getItemName(gradeList.Grade) }}</Option>
    </select>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Shift<span class="redcls">*</span></strong>
                </td>
                <td align="center">
                  <span
                    id="ctl00_ContentPlaceHolder1_lblPastShiftType"
                    disabled="disabled"
                    >{{ formLeft.shift }}</span
                  >
                </td>
                <td align="center">
                  <span v-if="viewMode">{{ formRight.shiftName }}</span>
                  <!-- <FormSelect
                    :items="shiftType"
                    item-name="Name"
                    item-value="id"
                    v-model="formRight.shiftId"
                    class="Dropstyle"
                    v-if="!viewMode"
                  /> -->
                  <select v-model="formRight.shiftId" class="Dropstyle" v-if="!viewMode">
      <Option v-for="(shiftType, i) in shiftType" :value="getItemValue(shiftType.id)" :key="i">{{ getItemName(shiftType.Name) }}</Option>
    </select>
                </td>
              </tr>
              <tr>
                <td>
                  &nbsp;
                </td>
                <td>
                  &nbsp;
                </td>
                <td>
                  &nbsp;
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Salary</strong>
                </td>
                <td>
                  &nbsp;
                </td>
                <td>
                  &nbsp;
                </td>
              </tr>
              <tr>
                <td class="alignSubhead">Basic <span class="redcls">*</span></td>
  
                <td align="right">
                  <span
                    id="ctl00_ContentPlaceHolder1_lblPastBasic"
                    disabled="disabled"
                    >{{ formLeft.basicSalary }}</span
                  >
                </td>
                <td align="right">
                  <span v-if="viewMode">{{ formRight.basicSalaryN }}</span>
  
                </td>
              </tr>
              <tr>
                <td class="alignSubhead">
                  HRA
                </td>
                <td align="right">
                  <span
                    id="ctl00_ContentPlaceHolder1_lblPastHRA"
                    disabled="disabled"
                    >{{ formLeft.HRA }}</span
                  >
                </td>
                <td align="right">
                  <span v-if="viewMode">{{ formRight.HRA }}</span>
  
                  <InputAmount class="inputStyle"
                    v-model="formRight.HRA"
                    v-if="!viewMode"
                  />
                </td>
              </tr>
              <!-- <tr>
                <td class="alignSubhead">
                  Conveyance
                </td>
  
                <td align="right">
                  <span
                    id="ctl00_ContentPlaceHolder1_lblPastConvey"
                    disabled="disabled"
                    >{{ formLeft.conveyance }}</span
                  >
                </td>
                <td align="right">
                  <span v-if="viewMode">{{ formRight.conveyance }}</span>
  
                  <InputAmount class="inputStyle"
                    v-model="formRight.conveyance"
                    v-if="!viewMode"
                  />
                </td>
              </tr> -->
              <!-- <tr>
                <td>
                  <strong>Monthly Allowance</strong>
                </td>
                <td>
                  &nbsp;
                </td>
                <td>
                  &nbsp;
                </td>
              </tr>
              <tr id="ctl00_ContentPlaceHolder1_trSoftTechProff">
                <td class="alignSubhead">
                  Professional
                </td>
                <td align="right">
                  <span
                    id="ctl00_ContentPlaceHolder1_lblPastProfess"
                    disabled="disabled"
                    >{{ formLeft.professional }}</span
                  >
                </td>
                <td align="right">
                  <span v-if="viewMode">{{ formRight.professional }}</span>
  
                  <InputAmount class="inputStyle"
                    v-model="formRight.professional"
                    v-if="!viewMode"
                  />
                </td>
              </tr>
  
              <tr>
                <td class="alignSubhead">
                  Special&nbsp;
                </td>
  
                <td align="right">
                  <span
                    id="ctl00_ContentPlaceHolder1_lblPastSpecial"
                    disabled="disabled"
                    >{{ formLeft.special }}</span
                  >
                </td>
                <td align="right">
                  <span v-if="viewMode">{{ formRight.special }}</span>
  
                  <InputAmount class="inputStyle"
                    v-model="formRight.special"
                    v-if="!viewMode"
                  />
                </td>
              </tr>
              <tr id="ctl00_ContentPlaceHolder1_trFamily">
                <td class="alignSubhead">
                  Family
                </td>
                <td align="right">
                  <span
                    id="ctl00_ContentPlaceHolder1_lblPastfamily"
                    disabled="disabled"
                    >{{ formLeft.family }}</span
                  >
                </td>
                <td align="right">
                  <span v-if="viewMode">{{ formRight.family }}</span>
                  <InputAmount class="inputStyle"
                    v-model="formRight.family"
                    v-if="!viewMode"
                  />
                </td>
              </tr>
  
              <tr>
                <td class="alignSubhead">
                  Shift
                </td>
  
                <td align="right">
                  <span
                    id="ctl00_ContentPlaceHolder1_lblPastshift"
                    disabled="disabled"
                    >{{ formLeft.shiftAll }}</span
                  >
                </td>
                <td align="right">
                  <span v-if="viewMode">{{ formRight.shiftAll }}</span>
                  <InputAmount class="inputStyle"
                    v-model="formRight.shift"
                    v-if="!viewMode"
                  />
                </td>
              </tr>
              <tr>
                <td class="alignSubhead">
                  Additional <span class="redcls">*</span>
                </td>
  
                <td align="right">
                  <span
                    id="ctl00_ContentPlaceHolder1_lblPastAdditional"
                    disabled="disabled"
                    >{{ formLeft.additional }}</span
                  >
                </td>
                <td align="right">
                  <span v-if="viewMode">{{ formRight.additional }}</span>
                  <InputAmount class="inputStyle"
                    v-model="formRight.additional"
                    v-if="!viewMode"
                  />
                </td>
              </tr> -->
              <tr>
                <td>
                  &nbsp;
                </td>
                <td>
                  &nbsp;
                </td>
                <td>
                  &nbsp;
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Monthly Fixed Salary</strong>
                </td>
  
                <td align="right">
                  <span
                    id="ctl00_ContentPlaceHolder1_lblPastMonthlyfixedsalary"
                    disabled="disabled"
                    >{{ formLeft.monthlyFixedSalary }}</span
                  >
                </td>
                <td align="right">
                  <strong>
                    <span id="ctl00_ContentPlaceHolder1_lblMonthlyFixedSalary">{{
                      formRight.monthlyFixedSalaryN
                    }}</span></strong
                  >
                </td>
              </tr>
              <tr>
                <td>
                  &nbsp;
                </td>
                <td>
                  &nbsp;
                </td>
                <td>
                  &nbsp;
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Monthly Reimbursement</strong>
                </td>
                <td>
                  &nbsp;
                </td>
                <td>
                  &nbsp;
                </td>
              </tr>
              <tr>
                <td class="alignSubhead">
                  Mobile / Phone
                </td>
  
                <td align="right">
                  <span
                    id="ctl00_ContentPlaceHolder1_lblPastMPhone"
                    disabled="disabled"
                    >{{ formLeft.mobile }}</span
                  >
                </td>
                <td align="right">
                  <span v-if="viewMode">{{ formRight.mobile }}</span>
  
                  <InputAmount class="inputStyle"
                    v-model="formRight.mobile"
                    v-if="!viewMode"
                  />
                </td>
              </tr>
              <tr id="ctl00_ContentPlaceHolder1_trConveyancereb">
                <td class="alignSubhead">
                  Conveyance
                </td>
                <td align="right">
                  <span
                    id="ctl00_ContentPlaceHolder1_lblPastMConvey"
                    disabled="disabled"
                    >{{ formLeft.conveyenceReimbursement }}</span
                  >
                </td>
                <td align="right">
                  <span v-if="viewMode">{{
                    formRight.conveyenceReimbursement
                  }}</span>
                  <InputAmount class="inputStyle"
                    v-model="formRight.conveyenceReimbursement"
                    v-if="!viewMode"
                  />
                </td>
              </tr>
  
              <tr>
                <td class="alignSubhead">
                  Business Development
                </td>
                <td align="right">
                  <span
                    id="ctl00_ContentPlaceHolder1_lblPastBussinessDevel"
                    disabled="disabled"
                    >{{ formLeft.businessDevelopment }}</span
                  >
                </td>
                <td align="right">
                  <span v-if="viewMode">{{ formRight.businessDevelopment }}</span>
                  <InputAmount class="inputStyle"
                    v-model="formRight.businessDevelopment"
                    v-if="!viewMode"
                  />
                </td>
              </tr>
              
              <tr id="ctl00_ContentPlaceHolder1_trMedicalReimbursement">
                <td class="alignSubhead">
                  Medical Reimbursement
                </td>
                <td align="right">
                  <span
                    id="ctl00_ContentPlaceHolder1_lblpastMedicalReimb"
                    disabled="disabled"
                    >{{ formLeft.medicalReimbursement }}</span
                  >
                </td>
                <td align="right">
                  <span v-if="viewMode">{{
                    formRight.medicalReimbursement
                  }}</span>
  
                  <InputAmount class="inputStyle"
                    v-model="formRight.medicalReimbursement"
                    v-if="!viewMode"
                  />
                </td>
              </tr>
              <tr>
                <td class="alignSubhead">
                  Monthly Performance Incentive
  
                </td>
                <td align="right">
                  <span
                    id="ctl00_ContentPlaceHolder1_lblPastBussinessDevel"
                    disabled="disabled"
                    >{{ formLeft.monthlyPerformanceIncentive }}</span
                  >
                </td>
                <td align="right">
                  <span v-if="viewMode">{{ formRight.monthlyPerformanceIncentive }}</span>
                  <InputAmount class="inputStyle"
                    v-model="formRight.monthlyPerformanceIncentive"
                    v-if="!viewMode"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  &nbsp;
                </td>
                <td>
                  &nbsp;
                </td>
                <td>
                  &nbsp;
                </td>
              </tr>
              <!-- <tr id="ctl00_ContentPlaceHolder1_trAnnualReimbursement">
                <td>
                  <strong>Annual Reimbursement</strong>
                </td>
                <td>
                  &nbsp;
                </td>
                <td>
                  &nbsp;
                </td>
              </tr> -->
  <!-- 
              <tr id="ctl00_ContentPlaceHolder1_trMedicalReimbursement">
                <td class="alignSubhead">
                  Medical Reimbursement
                </td>
                <td align="right">
                  <span
                    id="ctl00_ContentPlaceHolder1_lblpastMedicalReimb"
                    disabled="disabled"
                    >{{ formLeft.medicalReimbursement }}</span
                  >
                </td>
                <td align="right">
                  <span v-if="viewMode">{{
                    formRight.medicalReimbursement
                  }}</span>
  
                  <InputAmount class="inputStyle"
                    v-model="formRight.medicalReimbursement"
                    v-if="!viewMode"
                  />
                </td>
              </tr> -->
              <tr v-if="!viewMode">
                <td>
                  <strong>Monthly Salary Payable</strong>
                </td>
  
                <td align="right">
                  <span
                    disabled="disabled"
                    >{{ formLeft.monthlySalaryPayable }}</span
                  >
                </td>
                <td align="right">
                  <strong>
                    <span>{{
                      formRight.monthlySalaryPayable
                    }}</span></strong
                  >
                </td>
              </tr>
              <tr v-if="!viewMode">
                <td>
                  &nbsp;
                </td>
                <td>
                  &nbsp;
                </td>
                <td>
                  &nbsp;
                </td>
              </tr>
            
              <tr>
                <td>
                  <strong>Others</strong>
                </td>
                <td>
                  &nbsp;
                </td>
                <td>
                  &nbsp;
                </td>
              </tr>
              <!-- <tr>
                <td class="alignSubhead">
                  Leave Encashment
                </td>
  
                <td align="right">
                  <span
                    id="ctl00_ContentPlaceHolder1_lblPastleaveEncash"
                    disabled="disabled"
                    >{{ formLeft.leaveEncashment }}</span
                  >
                </td>
                <td align="right">
                  <span v-if="viewMode">{{ formRight.leaveEncashment }}</span>
  
                  <InputAmount class="inputStyle"
                    v-model="formRight.leaveEncashment"
                    v-if="!viewMode"
                  />
                </td>
              </tr> -->
              <!-- <tr>
                <td class="alignSubhead">
                  Medical Insurance
                </td>
  
                <td align="right">
                  <span
                    id="ctl00_ContentPlaceHolder1_lblPastMedicalInsur"
                    disabled="disabled"
                    >{{ formLeft.medicalInsurance }}</span
                  >
                </td>
                <td align="right">
                  <span v-if="viewMode">{{ formRight.medicalInsurance }}</span>
  
                  <InputAmount class="inputStyle"
                    v-model="formRight.medicalInsurance"
                    v-if="!viewMode"
                  />
                </td>
              </tr> -->
              <tr id="ctl00_ContentPlaceHolder1_trRelationshipAward">
                <td class="alignSubhead">
                  Relationship Award
                </td>
                <td align="right">
                  <span
                    id="ctl00_ContentPlaceHolder1_lblPastRelationship"
                    disabled="disabled"
                    >{{ formLeft.relationshipAward }}</span
                  >
                </td>
                <td align="right">
                  <span v-if="viewMode">{{ formRight.relationshipAward }}</span>
  
                  <InputAmount class="inputStyle"
                    v-model="formRight.relationshipAward"
                    v-if="!viewMode"
                  />
                </td>
              </tr>
  
              <tr id="ctl00_ContentPlaceHolder1_trPF">
                <td class="alignSubhead">
                  Provident Fund
                </td>
                <td align="right">
                  <span
                    id="ctl00_ContentPlaceHolder1_lblPastProvidentFund"
                    disabled="disabled"
                    >{{ formLeft.providentFund }}</span
                  >
                </td>
                <td align="right">
                  <span v-if="viewMode">{{ formRight.providentFund }}</span>
  
                  <InputAmount class="inputStyle"
                    v-model="formRight.providentFund"
                    v-if="!viewMode"
                  />
                </td>
              </tr>
  
              <tr>
                <td class="alignSubhead">
                  Bonus
                </td>
  
                <td align="right" style="width:25%;">
                  <span
                    id="ctl00_ContentPlaceHolder1_lblPastBonus"
                    disabled="disabled"
                    >{{ formLeft.bonus }}</span
                  >
                </td>
                <td align="right" style="width:25%;">
                  <span v-if="viewMode">{{ formRight.bonus }}</span>
  
                  <InputAmount class="inputStyle"
                    v-model="formRight.bonus"
                    v-if="!viewMode"
                  />
                </td>
                <td align="right" style="width:25%;">
                  <span>{{ bonusNew }}</span>
                </td>
              </tr>
              <tr>
                <td>
                  &nbsp;
                </td>
                <td>
                  &nbsp;
                </td>
                <td>
                  &nbsp;
                </td>
              </tr>
              <!-- <tr id="ctl00_ContentPlaceHolder1_trpaymentPay">
                <td class="alignSubhead">
                  Performance Pay
                </td>
                <td align="right">
                  <span
                    id="ctl00_ContentPlaceHolder1_lblPastPerformacePay"
                    disabled="disabled"
                    >{{ formLeft.performancePay }}</span
                  >
                </td>
                <td align="right">
                  <span v-if="viewMode">{{ formRight.performancePay }}</span>
  
                  <InputAmount class="inputStyle"
                    v-model="formRight.performancePay"
                    v-if="!viewMode"
                  />
                </td>
              </tr> -->
  
              <tr v-if="!viewMode">
                <td>
                  &nbsp;
                </td>
                <td>
                  &nbsp;
                </td>
                <td>
                  &nbsp;
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Cost to the Company</strong>
                </td>
  
                <td align="right">
                  <span
                    id="ctl00_ContentPlaceHolder1_lblPastCTC"
                    disabled="disabled"
                    >{{ formLeft.costToTheCompany }}</span
                  >
                </td>
                <td align="right">
                  <strong>
                    <span id="ctl00_ContentPlaceHolder1_lblCTC">{{
                      formRight.costToTheCompany
                    }}</span></strong
                  >
                </td>
                <td align="right">
                  <strong>
                    <span id="ctl00_ContentPlaceHolder1_lblCTCannually">{{
                      costToTheCompanyNew
                    }}</span></strong
                  >
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <br />
          <strong>Note :</strong>
          <br />
          <span id="ctl00_ContentPlaceHolder1_lblNotes"
            >Incentive and Local Conveyance (if applicable) as per Company
            Policies</span
          >
          <br />
          <span id="ctl00_ContentPlaceHolder1_PaymentNote">
            Performance Pay will be paid Twice a area in the Month of April and
            October</span
          >
          <br />
          <br />
          <table
            class="salaryContainer"
            width="825"
            border="0"
            cellspacing="0"
            cellpadding="3"
          >
            <tbody>
              <tr>
                <td width="250px">
                  <strong>
                    <span v-if="viewMode">
                      Date of Increment
                    </span>
                    <!-- <span v-if="!viewMode && isOnProbation == 0">
                      Date of Confirmation
                    </span> -->
                    <span v-else-if="!viewMode && isOnProbation == 1">
                      Date of Increment
                    </span> 
                    <span v-else-if="!viewMode && isOnProbation == 0">
                      Date of Joining
                    </span>
                  </strong>
                </td>
                <td align="center">
                  <span v-if="viewMode">{{ formFooter.dateOfIncrement }}</span>
                  <!-- <span v-if="!viewMode && isOnProbation == 0">{{
                    formFooter.dateOfConfirmation
                  }}</span> -->
                   <span v-else-if="!viewMode && isOnProbation == 1">
                   {{ formFooter.dateOfIncrement}}
                    </span> 
                  <span v-else-if="!viewMode && isOnProbation == 0">{{
                    formFooter.dateOfJoining
                  }}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <strong> <span>Service Agreement</span></strong>
                </td>
                <td align="center">
                  <span>{{ formFooter.serviceAgreement }}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>
                    <span v-if="viewMode">Date of next Increment</span>
                    <!-- <span v-else-if="!viewMode && isOnProbation == 0"
                      >Date of Increment</span
                    > -->
                    <span v-else-if="!viewMode && isOnProbation == 1"
                      >Date of next Increment</span
                    >
                    <span v-else-if="!viewMode && isOnProbation == 0">
                      Date of Confirmation
                    </span>
                  </strong>
                </td>
                <td align="center">
                  <span v-if="viewMode">{{
                    formFooter.dateOfNextIncrement
                  }}</span>
                  <!-- <span v-else-if="!viewMode && isOnProbation == 0">{{
                    formFooter.dateOfIncrement
                  }}</span> -->
                   <span v-else-if="!viewMode && isOnProbation == 1"
                      >{{formFooter.dateOfNextIncrement}}</span
                    >
                  <span v-else-if="!viewMode && isOnProbation == 0">{{
                    formFooter.dateOfConfirmation
                  }}</span>
                </td>
              </tr>
            </tbody>
          </table>
  
          <br />
          <br />
          <br />
          <table width="750" border="0" cellspacing="0" cellpadding="0">
            <tbody>
              <tr>
                <td>
                  <strong>Signature HR Executive</strong>
                </td>
                <td>
                  &nbsp;
                </td>
                <td>
                  <strong>Signature of HR Manager</strong>
                </td>
                <td>
                  <strong>Signature of the CEO</strong>
                </td>
                <td>
                  <span
                    id="ctl00_ContentPlaceHolder1_Label10"
                    disabled="disabled"
                    style="display:inline-block;width:107px;"
                  ></span>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Date</strong> :<span
                    id="ctl00_ContentPlaceHolder1_lblLastIncrementDate24"
                  ></span>
                </td>
                <td>
                  &nbsp;
                </td>
                <td>
                  <strong>Date</strong> :<span
                    id="ctl00_ContentPlaceHolder1_lblLastIncrementDate25"
                  ></span>
                </td>
                <td>
                  <strong>Date</strong> :<span
                    id="ctl00_ContentPlaceHolder1_lblLastIncrementDate26"
                  ></span>
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
          <br />
          <!-- </div>
        <div class="col-sm-2"></div>
      </FormRow> -->
        </div>
      </ValidationObserver>
      <ButtonGroup v-if="!viewMode">
        <FormButton type="primary" @click="prevStep" left-icon="ios-arrow-back"
          >Previous</FormButton
        >
        <FormButton type="success" @click="sendData()">Save</FormButton>
      </ButtonGroup>
      <Popup
        title="Success"
        type="success"
        :value="showMessage.isVisible"
        @close="() => (showMessage.isVisible = false)"
        :closable="true"
      >
        {{ showMessage.message }}
        <div slot="footer">
          <div slot="footer">
            <FormButton
              @click="
                () => {
                  $router.go(-1);
                }
              "
              >Ok</FormButton
            >
          </div>
        </div>
      </Popup>
      <Popup
        title="Information"
        type="info"
        :value="infoMessage.isVisible"
        @close="() => (showMessage.isVisible = false)"
        :closable="true"
      >
        {{ infoMessage.message }}
      </Popup>
    </FormWrapper>
  </template>
  
  <script>
  import FormWrapper from "Components/form/FormWrapper";
  import loadingMixin from "Mixins/loadingMixin";
  import PageTitle from "Components/layout/PageTitle";
  import moment from 'moment';
  import {
    getSalaryFixationByAppraisalId,
  } from "../api";
  import InputDate from "Components/form/InputDate";
  import validateSelectedRows from "Mixins/validateSelectedRows";
  import { ValidationObserver } from "vee-validate";
  export default {
    name: "index",
    mixins: [loadingMixin, validateSelectedRows],
    components: {
      FormWrapper,
      PageTitle,
      InputDate,
      ValidationObserver,
    },
    data() {
      return {
        showMessage: {
          isVisible: false,
        },
        infoMessage: {
          isVisible: false,
        },
        formLeft: {
          designation: null,
          group: null,
          grade: null,
          shift: null,
          basicSalary: null,
          HRA: null,
          conveyance: null,
          professional: null,
          special: null,
          family: null,
          shiftAll: null,
          additional: null,
          mobile: null,
          conveyenceReimbursement: null,
          businessDevelopment: null,
          medicalReimbursement: null,
          leaveEncashment: null,
          medicalInsurance: null,
          relationshipAward: null,
          providentFund: null,
          bonus: null,
          performancePay: null,
          costToTheCompany: null,
          dateofConfirmation: null,
          dateofIncrement: null,
          serviceAgreement: null,
          bonusAnually: null,
          monthlyFixedSalary: null,
          monthlyPerformanceIncentive:null,
          monthlySalaryPayable:null,
        },
        formRight: {
          designationName: "",
          designation: null,
          basicSalaryAmount: 0,
          basicSalaryN: "",
          hrGroupId: null,
          groupName: "",
          name: null,
          department: null,
          shift: null,
          shiftName: "",
          gradeName: "",
          lastIncrement: null,
          presentIncrement: null,
          HRA: 0,
          conveyance: 0,
          professional: 0,
          special: 0,
          family: 0,
          shiftId: 0,
          additional: 0,
          monthlyFixedSalary: 0,
          monthlyFixedSalaryN: "",
          mobile: 0,
          businessDevelopment: 0,
          conveyenceReimbursement: 2000, //default conveyenceReimbursement
          medicalReimbursement: 0,
          leaveEncashment: 0,
          medicalInsurance: 0,
          relationshipAward: 0,
          providentFund: 0,
          bonus: 0,
          performancePay: 0,
          softwareTechnicalProfessional: 0,
          costToTheCompany: 0,
          costToTheCompanyAnnual: 0,
          dateofIncrement: "",
          monthlyPerformanceIncentive:0,
          monthlySalaryPayable:null,
        },
        formFooter: {
          dateOfJoining: "",
          dateOfIncrement: "",
          dateOfConfirmation: "",
          serviceAgreement: "",
          dateOfNextIncrement: "",
        },
        designationList: [],
        gradeList: [],
        shiftType: [],
        hrGroupList: [],
        isOnProbation: 0,
        bonusNew: 0,
        costToTheCompanyNew: 0,
        viewMode: false,
      };
    },
    computed: {
      userData() {
        return this.$store.state.user.user;
      },
    },
    watch: {
    },
    created() {

      this.getDropDownData();
    },
    methods: {
      sendData() {
        const data = {
          staffId: this.form.selectedStaff,
          appraisedOn: this.form.appraisalOn,
          appraisedBy: this.form.appraisedBy,
          effectiveFrom: this.formRight.presentIncrement,
          designationId: this.formRight.designation,
          hrGroupId: this.formRight.hrGroupId,
          grade: this.formRight.grade,
          shiftId: this.formRight.shiftId,
          basicSalary: this.formRight.basicSalaryAmount,
          additional: this.formRight.additional,
          deductPF: this.form.pfDeduction,
          ratingId: this.form.rating,
        //  CTC:parseFloat(this.formRight.costToTheCompany.replace(/,/g, ''))
          CTC:this.formRight.costToTheCompany.match(/,/g)?parseFloat(this.formRight.costToTheCompany.replace(/,/g, '')):this.formRight.costToTheCompany,
          HRA: this.formRight.HRA,
          conveyance: this.formRight.conveyance,
          softwareTechnicalProfessinalAllowance: this.formRight.professional,
          specialAllowance: this.formRight.special,
          familyAllowance: this.formRight.family,
          shiftAllowance: this.formRight.shift,
          mobileReimbursement: this.formRight.mobile,
          conveyenceReimbursement: this.formRight.conveyenceReimbursement,
          businessdevelopmentReimbursement: this.formRight
            .businessdevelopmentReimbursement,
          medicalReimbursement: this.formRight.medicalReimbursement,
          leaveEncashment: this.formRight.leaveEncashment,
          medicalInsurance: this.formRight.medicalInsurance,
          relationshipAward: this.formRight.relationshipAward,
          bonus: this.formRight.bonus,
          performancePay: this.formRight.performancePay,
          staffPF: this.formRight.staffPF,
          modifiedBy: this.formRight.modifiedBy,
          isAppraisal:'Y',
          userLastIncDate: this.formRight.userLastIncDate,
          userAppraisalDue: this.formRight.userAppraisalDue,
          presentIncDate: this.formRight.presentIncDate,
          monthlyPerformanceIncentive: this.formRight.monthlyPerformanceIncentive,
        };
        staffApprasial(data).then((res) => {
          if (res.data[0].status == 1) {
            this.showMessage.isVisible = true;
            this.showMessage.message = res.data[0].message;
          } else {
            this.infoMessage.isVisible = true;
            this.infoMessage.message = res.data[0].message;
          }
        });
      },
      prevStep() {
        this.$emit("prevStep");
      },
      async getDropDownData() {
          this.viewMode = true;
          this.getSalaryFixatiationForTwoColunm();
      },
      // for view Mode
      getSalaryFixatiationForTwoColunm(){
        const data = {
          staffId: this.userData.user_id,
          effectiveFrom: new Date().toISOString().slice(0,10),
          year: new Date().toISOString().slice(0,10)
            ? new Date().toISOString().slice(0,10).split("-")[0]
            : "",
          month: new Date().toISOString().slice(0,10)
            ? new Date().toISOString().slice(0,10).split("-")[1]
            : "",
        };
        getSalaryFixationByAppraisalId(data).then(res=>{
  let previous=res.previous[0];
  let present=res.present[0];
  this.previousDataFill(previous);
  this.presentDataFill(present);
        });
      },
      previousDataFill(data){
          this.formLeft.designation = data.Designation;
          this.formLeft.shift = data.Shift;
          this.formLeft.grade = data.Grade_id;
          this.formLeft.basicSalary = data.BasicSalary?data.BasicSalary:'';
          this.formLeft.HRA = data.HRA?data.HRA:'';
  
          this.formLeft.group = data.Group;
          this.formLeft.conveyance = data.Conveyence;
          this.formLeft.professional = data.PerformancePay;
          this.formLeft.special = data.Special;
          this.formLeft.family = data.Family;
          this.formLeft.shiftAll = data.ShiftAll;
          this.formLeft.additional = data.Additional;
          this.formLeft.mobile=data.MobilePhone?data.MobilePhone:'';
          this.formLeft.conveyenceReimbursement =
            data.conveyenceReimbursement;
          this.formLeft.businessDevelopment = data.BusinessDevelopment?data.BusinessDevelopment:"";
          this.formLeft.medicalReimbursement = data.MedicalReimbursement?data.MedicalReimbursement:'';
          this.formLeft.leaveEncashment = data.LeaveEncashment;
          this.formLeft.medicalInsurance = data.MedicalInsurance?data.MedicalInsurance:'';
          this.formLeft.relationshipAward = data.RelationshipAward?data.RelationshipAward:'';
          this.formLeft.providentFund = data.ProvidentFund?data.ProvidentFund:'';
          this.formLeft.bonus = data.Bonus?data.Bonus:'';
          this.formLeft.performancePay = data.PerformancePay?data.PerformancePay:'';
          this.formLeft.costToTheCompany =data.CostToTheCompany?data.CostToTheCompany.toLocaleString('en-IN'):"";
          this.formLeft.dateofConfirmation = data.DateOfConfirmation;
          this.formLeft.dateofIncrement = data.DateOfIncrement;
          this.formLeft.serviceAgreement = data.ServiceAgreement;
          this.formLeft.bonusAnually = data.BonusAnually.toLocaleString('en-IN');
          this.formLeft.monthlyFixedSalary =data.MonthlyFixedSalary?data.MonthlyFixedSalary.toLocaleString('en-IN'):'';
      },
      presentDataFill(data){
        this.formRight.name =data.NAME;
          this.formRight.department = data.Department;
          this.formRight.designation = data.Designation;
          this.formRight.lastIncrement = data.LastIncrementDate;
          this.formRight.presentIncrement = data.PresentAppraisalDate;
          this.formRight.monthlyFixedSalaryN = data.MonthlyFixedSalary?data.MonthlyFixedSalary.toLocaleString('en-IN'):'';
          this.formRight.shift = data.ShiftAll;
          this.formRight.grade = data.Grade_id;
          this.formRight.basicSalaryAmount = data.BasicSalary?data.BasicSalary:'';
          this.formRight.HRA = data.HRA?data.HRA:'';
  
          this.formRight.group = data.Group;
          this.formRight.conveyance = data.Conveyence;
          this.formRight.professional = data.PerformancePay;
          this.formRight.special = data.Special;
          this.formRight.family = data.Family;
          this.formRight.shiftAll = data.ShiftAll;
          this.formRight.additional = data.Additional;
          this.formRight.mobile=data.MobilePhone?data.MobilePhone:'';
          this.formRight.conveyenceReimbursement =
            data.ConveyenceReimbursement? data.ConveyenceReimbursement:'';
          this.formRight.businessDevelopment = data.BusinessDevelopment?data.BusinessDevelopment:'';
          this.formRight.medicalReimbursement = data.MedicalReimbursement?data.MedicalReimbursement:'';
          this.formRight.leaveEncashment = data.LeaveEncashment?data.LeaveEncashment:'';
          this.formRight.medicalInsurance = data.MedicalInsurance?data.MedicalInsurance:'';
          this.formRight.relationshipAward = data.RelationshipAward?data.RelationshipAward:"";
          this.formRight.providentFund = data.ProvidentFund?data.ProvidentFund:'';
          this.formRight.bonus = data.Bonus?data.Bonus:'';
          this.formRight.performancePay = data.PerformancePay?data.PerformancePay:'';
          this.formRight.monthlyPerformanceIncentive =data.monthlyPerformanceIncentive?data.monthlyPerformanceIncentive:'' 
          this.formRight.costToTheCompany = data.CostToTheCompany?data.CostToTheCompany.toLocaleString('en-IN'):'';
  
          this.formRight.dateofConfirmation = data.DateOfConfirmation;
          this.formRight.dateofIncrement = data.DateOfIncrement;
          this.formRight.serviceAgreement = data.ServiceAgreement;
          this.formRight.bonusAnually = data.BonusAnually;
          this.formRight.monthlyFixedSalary =data.MonthlyFixedSalary?data.MonthlyFixedSalary.toLocaleString('en-IN'):'';
          // first 4 view
          this.formRight.designationName = data.Designation;
          this.formRight.basicSalaryN = data.BasicSalary?data.BasicSalary.toLocaleString('en-IN'):'';
          this.formRight.gradeName = data.Grade_id;
          this.formRight.groupName = data.Group;
          this.formRight.shiftName = data.Shift;
          // third colunm
          this.bonusNew =data.BonusAnually?data.BonusAnually.toLocaleString('en-IN'):'';
          this.costToTheCompanyNew =data.CostToTheCompanyAnnua?data.CostToTheCompanyAnnual.toLocaleString('en-IN'):'';
          // footer table
          this.formFooter.serviceAgreement = data.ServiceAgreement?data.ServiceAgreement:'N/A';
          this.formFooter.dateOfIncrement = data.DateOfIncrement;
          this.formFooter.dateOfNextIncrement =data.NextIncrementDate;
  },
    },
  };
  </script>
  <style lang="scss">
  .error {
    color: #ff3547;
    margin-top: 2px;
    max-width: 100%;
    font-size: 11px;
    line-height: 16px;
    font-weight: 400;
  }
  // table style
  
  .salaryContainer {
    font-size: 12px;
    font-family: Arial, Helvetica, sans-serif, Calibri;
  }
  
  .salaryContainer h2 {
    font-size: 18px;
    font-weight: normal;
  }
  .salaryContainer table {
    border-collapse: collapse;
  }
  .salaryContainer td,
  th {
    border: 1px solid #000000;
  }
  .textboxstyle {
    text-align: right;
    text-align-last: right;
    float: right;
  }
  
  .bckgrd {
    background-color: LightGrey;
  }
  .Dropstyle{
    text-align: center;
      text-align-last: center;
      font-size: 11px;
      // background: #f8f8f8;
      border: 1px #cecece solid;
  }
  .inputStyle{
    text-align: right;
      text-align-last: right;
      float: right;
      font-size: 11px;
      // background: #f8f8f8;
      // border: 1px #cecece solid;
      margin: 0px;
  }
  .redcls {
      color: #FF0000;
  }
  .salaryContainer tr, td {
    padding: 0px 5px 0px 3px ;
    
  }
  .salaryContainer td{
    height: 25px;
  }
  .salaryContainer input {
    padding: 0px 0px 0px 0px;
    height: 20px;
  }
  .salaryContainer select {
    padding: 0px 0px 0px 5px;
    height: 20px;
    width:188px
  }
  .spanClass{
    line-height:0px;
  }
  </style>
  